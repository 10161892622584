import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { FaRegClock, FaCog, FaUsers, FaHistory } from "react-icons/fa";
import { MdMonitor } from "react-icons/md";
import moment from "moment";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import { logout } from "../../../actions/auth";
import { setFields } from "../../../actions/selected_values";
import ModalSplitScreen from "./ModalSplitScreen";
import ModalLogs from "./ModalLogs";
import ModalUsers from "./ModalUsers";
import Tooltip from "@mui/material/Tooltip";
import { Link } from "react-router-dom";


function Footer(props) {
  const [currentTime, setCurrentTime] = useState("");
  const [modal, setModal] = useState(false);
  const [isOpenUsers, setIsOpenUsers] = useState(false);
  const [isOpenLogs, setIsOpenLogs] = useState(false);
  const [isOpenSplitScreen, setIsOpenSplitScreen] = useState(false);
  // const { width, height } = useWindowDimensions();
  // const history = useHistory();

  const toggle = () => setModal(!modal);
  const toggleUsers = () => setIsOpenUsers(!isOpenUsers);
  const toggleLogs = () => setIsOpenLogs(!isOpenLogs);
  const toggleSplitScreen = () => setIsOpenSplitScreen(!isOpenSplitScreen);

  useEffect(() => {
    setInterval(function () {
      setCurrentTime(moment().format("YYYY-MM-DD HH:mm:ss"));
    }, 500);
  }, []);
  return (
    <div
      style={{
        position: "absolute",
        bottom: 0,
        zIndex: 1,
        width: "100%",
        backgroundColor: props.layout.color_footer,
        height: props.layout.footer_height,
        paddingLeft: props.layout.width_sidebar,
      }}
    >
      <div
        style={{
          width: "100%",
          height: "100%",
          padding: 5,
          paddingLeft: 20,
          paddingRight: 20,
        }}
      >
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            color: "white",
            fontSize: 14,
            textDecoration: "italics",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <FaRegClock
                style={{ fontSize: 17, marginRight: 10 }}
              ></FaRegClock>
              <i>{currentTime}</i>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: 15,
                borderLeft: "1px solid white",
                paddingLeft: 15,
              }}
            >
              <i>{props.auth.username}</i>
            </div>
          </div>

          <div style={{ display: "flex", alignItems: "center" }}>
            {
              window.screen.width > 1300 ? (
                <Tooltip title="Split screen">
                  <div>
                    <MdMonitor
                      onClick={() => toggleSplitScreen()}
                      style={{ fontSize: 19, cursor: "pointer", marginRight: 15 }}
                    ></MdMonitor>
                  </div>
                </Tooltip>
              ) : null}

            {props.auth.is_superuser === true ? (
              <Tooltip title="Logs">
                <div>
                  <FaHistory
                    onClick={() => toggleLogs()}
                    style={{ fontSize: 17, cursor: "pointer", marginRight: 15 }}
                  ></FaHistory>
                </div>
              </Tooltip>
            ) : null}
            {props.auth.is_sub_actor === true ? null : (
              <Tooltip title="Users">
                <div>
                  <Link to="/clicc/dashboard/users">
                    <FaUsers
                      style={{ fontSize: 20, cursor: "pointer", marginRight: 15, color: "white" }}
                    ></FaUsers>
                  </Link>

                </div>
              </Tooltip>
            )}

            <Tooltip title="Settings">
              <div>
                <FaCog
                  style={{ fontSize: 17, cursor: "pointer" }}
                  onClick={() => setModal(true)}
                ></FaCog>
              </div>
            </Tooltip>
          </div>
        </div>
      </div>
      {/* split screen modal */}
      <ModalSplitScreen
        fields={props.fields}
        setFields={props.setFields}
        isOpen={isOpenSplitScreen}
        setIsOpen={setIsOpenSplitScreen}
      ></ModalSplitScreen>
      {/* logs modal */}
      <ModalLogs isOpen={isOpenLogs} setIsOpen={setIsOpenLogs}></ModalLogs>
      {/* users modal */}
      <ModalUsers isOpen={isOpenUsers} setIsOpen={setIsOpenUsers}></ModalUsers>
      {/* settings modal */}
      <Modal
        isOpen={modal}
        toggle={toggle}
        size="lg"
        style={{ fontFamily: "Montserrat", fontWeight: 500 }}
      >
        <ModalHeader toggle={toggle}>
          <span>Settings</span>
        </ModalHeader>
        <ModalBody>
          Leave the application:
          <Button
            color="danger"
            onClick={() => props.logout()}
            style={{ marginLeft: 10 }}
          >
            Logout
          </Button>
        </ModalBody>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => ({
  fields: state.selected_values.fields,
  layout: state.layout,
  auth: state.auth,
});

export default connect(mapStateToProps, { logout, setFields })(Footer);

import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import { alpha, styled } from "@mui/material/styles";
import TreeView from "@mui/lab/TreeView";
import TreeItem, { TreeItemProps, treeItemClasses } from "@mui/lab/TreeItem";
import Collapse from "@mui/material/Collapse";
import { useSpring, animated } from "@react-spring/web";
import { TransitionProps } from "@mui/material/transitions";

function MinusSquare(props: SvgIconProps) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
    </SvgIcon>
  );
}

function PlusSquare(props: SvgIconProps) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
    </SvgIcon>
  );
}

function CloseSquare(props: SvgIconProps) {
  return (
    <SvgIcon
      className="close"
      fontSize="inherit"
      style={{ width: 14, height: 14 }}
      {...props}
    >
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
    </SvgIcon>
  );
}

function TransitionComponent(props: TransitionProps) {
  const style = useSpring({
    from: {
      opacity: 0,
      transform: "translate3d(20px,0,0)",
    },
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
    },
  });

  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  );
}

const StyledTreeItem = styled((props: TreeItemProps) => (
  <TreeItem {...props} TransitionComponent={TransitionComponent} />
))(({ theme }) => ({
  [`& .${treeItemClasses.iconContainer}`]: {
    "& .close": {
      opacity: 0.3,
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 15,
    paddingLeft: 18,
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
  },
}));

function ModalSubject(props) {
  const [selected, setSelected] = useState("");

  useEffect(() => {
    if (props.isOpen === true) {
      setSelected(props.subject);
    }
  }, [props.isOpen]);

  const toggle = () => props.setIsOpen(!props.isOpen);

  const handleSelect = (event, nodeIds) => {
    setSelected(nodeIds);
  };

  var data = {};
  var createNestedObject = function (base, names) {
    for (var i = 0; i < names.length; i++) {
      base = base[names[i]] = base[names[i]] || {};
    }
  };


  props.treeData.forEach((ls) => {
    ls.forEach((str, index) => {
      var arr_keys = ls.slice(0, index + 1);
      createNestedObject(data, arr_keys);
    });
  });

  const generateItem = (obj, prevKey = "") => {
    return (
      <>
        {Object.keys(obj).map((k) => {
          let newKey = `${prevKey};;${k}`;
          return (
            <StyledTreeItem nodeId={newKey} label={k}>
              {Object.keys(obj[k]).length > 0
                ? generateItem(obj[k], newKey)
                : null}
            </StyledTreeItem>
          );
        })}
      </>
    );
  };
  return (
    <Modal isOpen={props.isOpen} toggle={toggle} unmountOnClose={true}>
      <ModalHeader toggle={toggle} style={{ fontFamily: "Montserrat" }}>
        Ontology
      </ModalHeader>
      <ModalBody
        style={{ fontFamily: "Montserrat", fontWeight: 500, fontSize: 14 }}
      >
        <TreeView
          aria-label="customized"
          defaultExpanded={props.subject
            .split(";;")
            .slice(1)
            .map(
              (str, index) =>
                `;;${props.subject
                  .split(";;")
                  .slice(1, index + 2)
                  .join(";;")}`
            )}
          defaultCollapseIcon={<MinusSquare />}
          defaultExpandIcon={<PlusSquare />}
          defaultEndIcon={<CloseSquare />}
          onNodeSelect={handleSelect}
          selected={selected}
          // expanded={expanded}
          sx={{
            flexGrow: 1,
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          {generateItem(data)}
        </TreeView>
      </ModalBody>
      <ModalFooter style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          {props.subject !== "" ? (
            <Button
              color="primary"
              outline
              onClick={() => {
                setSelected("");
                props.setSubject("");
                toggle();
              }}
              style={{ fontFamily: "Montserrat", fontWeight: 500 }}
            >
              Reset
            </Button>
          ) : null}
        </div>
        <div>
          <Button
            color="success"
            disabled={selected === "" ? true : false}
            onClick={() => {
              props.setSubject(selected);
              setSelected("");
              toggle();
            }}
            style={{
              fontFamily: "Montserrat",
              fontWeight: 500,
              marginRight: 10,
            }}
          >
            Apply
          </Button>
          <Button
            color="secondary"
            onClick={() => {
              setSelected("");
              toggle();
            }}
            style={{ fontFamily: "Montserrat", fontWeight: 500 }}
          >
            Close
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
}

export default ModalSubject;
